import React, { useState, useEffect } from "react";
import "./textMedia.css";
import axios from "axios";
import Common from "../../utils/common";
import CardPost from "./cardPost";
import TextCard from "./textCard";
import { IoGridOutline } from "react-icons/io5";
import { FaListUl } from "react-icons/fa";
import GridPost from "./gridPost";
const TextMedia = ({ input, activeTab }) => {
  const [activeSwitch, setActiveSwitch] = useState("Grid");
  const [invitations, setInvitations] = useState([]);
  const [filteredPosts, setFilteredPosts] = useState([]);
  const handleTabChange = (tab) => {
    setActiveSwitch(tab);
  };
  const getAll = async () => {
    try {
      const res = await axios.get(
        `${process.env.PUBLIC_URL}/xakal/get-all-text`
      );

      // Get the logged-in user ID
      const loggedInUserId = Common.loggedIn();

      // Filter posts based on privacy
      const filteredPosts = res.data.social.filter((post) => {
        const isUserAssociated = invitations.some(
          (invitation) =>
            invitation.appUserId === loggedInUserId ||
            invitation.associateId === loggedInUserId
        );

        return (
          (post.privacy === "PUBLIC" || // Show all public posts
            (post.privacy === "ASSOCIATE" && isUserAssociated)) && // Show associate posts only if the user is connected
          post.category === "Text" // Show only posts with category "Text"
        );
      });

      setFilteredPosts(filteredPosts);
      console.log(filteredPosts, "filtered posts");
    } catch (error) {
      console.error("Error fetching all text data:", error);
    }
  };

  const getMyInvitations = async () => {
    try {
      const res = await axios.get(
        `${process.env.PUBLIC_URL}/xakal/get/myInvitations/${Common.loggedIn()}`
      );
      const dt = res?.data?.filter((e) => e.connectionStatus === "ASSOCIATE");
      setInvitations(dt);
    } catch (error) {
      console.error("Error fetching invitations:", error);
    }
  };

  useEffect(() => {
    getMyInvitations();
  }, []);

  useEffect(() => {
    const loggedInUserId = Common.loggedIn();
    const isUserAssociated = invitations.some(
      (invitation) =>
        invitation.appUserId === loggedInUserId ||
        invitation.associateId === loggedInUserId
    );

    // Only call getAll if the user is associated
    if (isUserAssociated) {
      getAll();
    }
  }, [invitations]);

  return (
    <>
      <TextCard activeTab={activeTab} input={input} getAll={getAll} />
      <div className="toggleFixed">
        <IoGridOutline
          size={25}
          onClick={() => setActiveSwitch("Grid")}
          style={{
            border: activeSwitch === "Grid" ? "4px solid purple" : "4px solid transparent",
          }}
        />
        <FaListUl
          size={25}
          onClick={() => setActiveSwitch("List")}
          style={{
            border: activeSwitch === "List" ? "4px solid purple" : "4px solid transparent",
          }}
        />
      </div>
      {activeSwitch === "Grid" ? (
        <GridPost filteredPosts={filteredPosts} input={input} getAll={getAll} activeTab={activeTab}/>
      ) : activeSwitch === "List" ? (
        <CardPost filteredPosts={filteredPosts} input={input} getAll={getAll} activeTab={activeTab}/>
      ) : (
        ""
      )}
    </>
  );
};

export default TextMedia;
