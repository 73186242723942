import React, { useEffect, useState } from "react";
import "./textMedia.css";
import companyLogo from "../../images/profile-image.svg";
import Slider from "react-slick";
import axios from "axios";
import parse from "html-react-parser";
import Common from "../../utils/common";
import Save from "../../images/save.svg";
import Comment from "../../images/Message.png";
import iBookmarkActive from "../../images/icons/bookmark-active.svg";
const GridPost = ({ filteredPosts, input, getAll, activeTab }) => {
  const [openComment, setOpenComment] = useState();
  const [newComment, setNewComment] = useState("");
  const userId = Common.loggedIn();
  const [savedSocialMedia, setSavedSocialMedia] = useState([]);

  const handleCommentChange = (e) => setNewComment(e.target.value);

  const TimeAgo = ({ timestamp }) => {
    const [timeAgo, setTimeAgo] = useState("");
    useEffect(() => {
      const calculateTimeAgo = () => {
        const currentTime = new Date();
        const postedTime = new Date(timestamp);
        const timeDifference = currentTime - postedTime;
        const minutes = Math.floor(timeDifference / (1000 * 60));
        const hours = Math.floor(timeDifference / (1000 * 60 * 60));
        const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        const months = Math.floor(timeDifference / (1000 * 60 * 60 * 24 * 30));
        const years = Math.floor(timeDifference / (1000 * 60 * 60 * 24 * 365));
        if (minutes < 60) {
          setTimeAgo(`${minutes} minute${minutes === 1 ? "" : "s"} ago`);
        } else if (hours < 24) {
          setTimeAgo(`${hours} hour${hours === 1 ? "" : "s"} ago`);
        } else if (days < 30) {
          setTimeAgo(`${days} day${days === 1 ? "" : "s"} ago`);
        } else if (months < 12) {
          setTimeAgo(`${months} month${months === 1 ? "" : "s"} ago`);
        } else {
          setTimeAgo(`${years} year${years === 1 ? "" : "s"} ago`);
        }
      };
      calculateTimeAgo();
    }, [timestamp]);

    return <span className="mes-time">{timeAgo}</span>;
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const handleComment = (postId) => {
    setOpenComment((prevId) => (prevId === postId ? null : postId));
  };

  const handleCommentSubmit = async (id) => {
    try {
      const payload = {
        commentText: newComment,
        createdBy: input.userId,
      };

      const res = await axios.put(
        `${process.env.PUBLIC_URL}/xakal/update-social-comment/${id}`,
        payload
      );

      setOpenComment(false);
      setNewComment("");
      getAll();
      console.log(res.data, "datasocial");
    } catch (error) {
      console.error("Error during submission:", error);
    }
  };

  const voteSubmit = async (postId, optionText) => {
    try {
      const response = await axios.put(
        `${process.env.PUBLIC_URL}/xakal/poll-create`,
        {
          postId,
          optionText,
          userId,
        }
      );
      getAll(); // Refresh posts after vote
    } catch (error) {
      console.error("Error recording vote:", error);
    }
  };
  const updateToUnsavedSocial = async (savedId) => {
    try {
      // Send a PUT request to your backend to toggle the favorite status
      const response = await axios.put(
        `${process.env.PUBLIC_URL}/xakal/unsaved-social/${savedId}`
      );

      if (response.status === 200) {
        // Update the favorite status in the state
        setSavedSocialMedia((prevSavedInterviews) => {
          const index = prevSavedInterviews.indexOf(savedId);
          if (index !== -1) {
            return prevSavedInterviews.filter(
              (interviewId) => interviewId !== savedId
            );
          } else {
            return [...prevSavedInterviews, savedId];
          }
        });
        // setOpenForm(false);
      } else {
        console.error("Failed to toggle favorite status");
      }
    } catch (error) {
      console.error("Error updating favorite status:", error);
    }
  };
  const updateToSavedMedia = async (savedId) => {
    try {
      const response = await axios.post(
        `${process.env.PUBLIC_URL}/xakal/saved-social/${savedId}`
      );

      if (response.status === 200) {
        // Update the favorite status in the state
        setSavedSocialMedia((prevSavedInterviews) => [
          ...prevSavedInterviews,
          savedId,
        ]);
        // setOpenForm(false);
      } else {
        console.error("Failed to toggle favorite status");
      }
    } catch (error) {
      console.error("Error updating favorite status:", error);
    }
  };
  return (
    <div className="cardContainers-wrap">
      {filteredPosts.length > 0 ? (
        filteredPosts.map((post) => {
          const totalVotes = post.optionsPoll
            ? post.optionsPoll.reduce((acc, option) => acc + option.votes, 0)
            : 0;
            const saved = post.savedBy.find(
              (fav) => fav.userId === userId
            );
  
            const savedApplied = saved && saved.status === "saved" ? true : false;
          return (
            
            <div
              key={post._id}
              className="cardContainers cardContainers-grid"
              // style={{ margin: "20px 0" }}
            >
              <div className="contentDiv" style={{ gap: "10px" }}>
                <img
                  src={post.aboutDetail?.avatar || companyLogo}
                  alt="Profile"
                  className="profileImage"
                />
                <div className="textContents">
                  {post.aboutDetail.firstName} {post.aboutDetail.lastName}
                </div>
                <div>
                  <TimeAgo timestamp={post.postedBy.createdAt} />
                </div>
              </div>
              {activeTab === "Text" && (
                <div className="text-post-content">{post?.text}</div>
              )}
              {activeTab === "Polls" && post.optionsPoll && (
                <>
                  <div>{post.pollTitle}</div>
                  {post.optionsPoll.map((option) => {
                    const hasVoted = option.user.includes(userId);
                    const votePercentage =
                      totalVotes > 0 ? (option.votes / totalVotes) * 100 : 0;

                    return (
                      <div key={option.optionText} style={{ padding: "10px" }}>
                        {hasVoted ? (
                          <div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <span>{option.optionText}</span>
                              <span>
                                {option.votes} votes (
                                {votePercentage.toFixed(1)}%)
                              </span>
                            </div>
                            <div
                              style={{
                                background: "#e0e0e0",
                                borderRadius: "8px",
                                overflow: "hidden",
                              }}
                            >
                              <div
                                style={{
                                  width: `${votePercentage}%`,
                                  background: "#4caf50",
                                  height: "10px",
                                  borderRadius: "8px",
                                }}
                              />
                            </div>
                          </div>
                        ) : (
                          <button
                            className="button2"
                            disabled={hasVoted} // Button will be disabled if the user has voted
                            onClick={() =>
                              voteSubmit(post._id, option.optionText)
                            } // Calls the voteSubmit function
                          >
                            {option.optionText}
                          </button>
                        )}
                      </div>
                    );
                  })}
                  <div>{post.pollCaptions}</div>
                </>
              )}

              {activeTab === "Article" && (
                <>
                  <div className="text-post-content">{post?.articleTitle}</div>
                  <div className="text-post-content">
                    {parse(post?.articleDescription)}
                  </div>
                </>
              )}

              {(activeTab === "Photo" ||
                activeTab === "Video" ||
                activeTab === "Article") && (
                <>
                  <div className="text-post-content">{post?.shortDesc}</div>
                  <div className="text-post-content">{post?.longDesc}</div>
                </>
              )}

              {post.media && post.media.length > 0 && (
                <Slider {...settings} className="image-carousel">
                  {post.media.map((image, index) => (
                    <div key={index} className="carousel-slide">
                      <img src={image.url} alt={`Post Image ${index}`} />
                    </div>
                  ))}
                </Slider>
              )}
              <div className="interactions">
                <div className="comment-wrap">
                  <div className="comment-text">{post.comments.length}</div>
                  <div
                    className="comment-button"
                    onClick={() => handleComment(post._id)}
                  >
                    <img src={Comment} alt="img" />
                  </div>
                </div>
                {savedApplied || savedSocialMedia.includes(post?._id) ? (
                  <>
                    <button 
                     onClick={(e) => {
                      e.stopPropagation();
                      updateToUnsavedSocial(post?._id);
                    }}
                    >
                      <img src={iBookmarkActive} alt="img" />
                    </button>
                  </>
                ) : (
                  <button 
                  onClick={(e) => {
                    e.stopPropagation();
                    updateToSavedMedia(post?._id);
                  }}
                  >
                    <img src={Save} alt="img" />
                  </button>
                )}
              </div>

              {openComment === post._id && (
                <div>
                  {post.comments.map((ind, index) => (
                    <div key={index} className="inputComment">
                      <img
                        src={ind?.aboutDetail?.avatar || companyLogo}
                        alt="Profile"
                        className="profileImage"
                      />
                      <p>{ind?.text}</p>
                      <TimeAgo timestamp={ind?.createdAt} />
                    </div>
                  ))}
                  <div className="inputComment">
                    <img
                      src={input?.avatar || companyLogo}
                      alt="Profile"
                      className="profileImage"
                    />
                    <input
                      type="text"
                      placeholder="What's on your mind?"
                      className="inputField"
                      value={newComment}
                      onChange={handleCommentChange}
                    />
                    <button
                      className="like-button"
                      onClick={() => handleCommentSubmit(post._id)}
                    >
                      Send
                    </button>
                  </div>
                </div>
              )}
            </div>
          
          );
        })
      ) : (
        <p className="no-post">No posts available.</p>
      )}
    </div>
  );
};

export default GridPost;
