import React from "react";
import { FaFile } from "react-icons/fa";
import "./textMedia.css";
const PollMediaForm = ({
  pollTitle,
  setPollTitle,
  pollOption,
  setPollOption,
  pollCaption,setPollCaption,
  handleToggle
}) => {
    const addOption = () => {
        setPollOption([...pollOption, { optionText: "", votes: 0 }]);
      };
    
      // Function to handle option text change
      const handleOptionChange = (index, newText) => {
        const updatedOptions = [...pollOption];
        updatedOptions[index].optionText = newText;
        setPollOption(updatedOptions);
      };
  return (
    <div>
      <div className="textContent">
        <input
          type="text"
          placeholder="What's on your mind?"
          className="inputField"
          value={pollTitle}
          onChange={(e) => setPollTitle(e.target.value)}
        />
      </div>
      <div className="optionsContainerss">
        {pollOption.map((option, index) => (
          <input
            key={index}
            type="text"
            placeholder={`Option ${index + 1}`}
            className="inputField"
            value={option.optionText}
            onChange={(e) => handleOptionChange(index, e.target.value)}
          />
        ))}

        <div
          className="option"
          style={{
            cursor: "pointer",
            padding: "10px",
            background: "#f0f0f0",
            border: "1px solid #ccc",
          }}
          onClick={addOption}
        >
          <FaFile size={20} /> File +
        </div>
      </div>
      <div className="textContent">
        Short Description
        <textarea
          value={pollCaption}
          placeholder="What's on your mind?"
          className="inputField"
          onChange={(e) => setPollCaption(e.target.value)}
          rows={3}
        />
        <div className="character">{pollCaption?.length}/300 Characters</div>
      </div>
      <div className="buttonDiv">
        <button className="button1" onClick={handleToggle}>
          Next
        </button>
      </div>
    </div>
  );
};

export default PollMediaForm;
