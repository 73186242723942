/* eslint-disable jsx-a11y/img-redundant-alt */
import { toast } from "react-toastify";
import React, { useState, useEffect, useRef } from "react";
import "./textMedia.css";
import companyLogo from "../../images/profile-image.svg";
import { Dialog, DialogContent } from "@mui/material";
import Toggle from "react-toggle";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment-timezone";
import Calender from "../../images/Calendar.svg";
import axios from "axios";
import Common from "../../utils/common";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaPhotoVideo, FaPoll } from "react-icons/fa";
import { FaVideo } from "react-icons/fa6";
import { RxHamburgerMenu } from "react-icons/rx";
import { MdWork } from "react-icons/md";
import { IoMdArrowDropdown } from "react-icons/io";
import { CiStreamOn } from "react-icons/ci";
import { FaPodcast } from "react-icons/fa";
import { MdEvent } from "react-icons/md";
import { RiArticleLine } from "react-icons/ri";
import { FaBookOpen } from "react-icons/fa";
import { TbBookFilled } from "react-icons/tb";
import { AiOutlineSnippets } from "react-icons/ai";
import CloseIcon from "../../images/CloseIcon.svg";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import EbookMediaForm from "./ebookMediaForm";
import PollMediaForm from "./pollMediaForm";
import PodCastForm from "./podcastForm";
const TextCard = ({ activeTab, input, getAll }) => {
  const [inputValue, setInputValue] = useState("");
  const [invitations, setInvitations] = useState([]);
  const [openToggle, setOpenToggle] = useState(false);
  const [openPhotoForm, setOpenPhotoForm] = useState(false);
  const [openVideoForm, setOpenVideoForm] = useState(false);
  const [open, setOpen] = useState(false);
  const [scheduleToggle, setScheduleToggle] = useState(false);
  const [openSchedulePost, setOpenSchedulePost] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedTimezone, setSelectedTimezone] = useState();
  const [showDatePicker, setShowDatePicker] = useState(false); // Controls date picker visibility
  const [privacy, setPrivacy] = useState("ASSOCIATE");
  const [images, setImages] = useState([]);
  const [imagesCover, setImagesCover] = useState(null);
  const [videos, setVideos] = useState([]);
  const [openMore, setOpenMore] = useState(false);
  const [articleOpen, setArticleOpen] = useState(false);
  const [shortDesc, setShortDesc] = useState("");
  const [longDesc, setLongDesc] = useState("");
  //article
  const [articleTitle, setArticleTitle] = useState("");
  const [articleDescription, setArticleDescription] = useState("");
  const [ebookOpen, setEbookOpen] = useState(false);
  //ebook

  //end of ebook
  const handleEbookOpen = () => {
    setEbookOpen((prevState) => !prevState);
  };
  const handleArticleForm = () => {
    setArticleOpen((prevState) => !prevState);
  };
  const handlePhotoOpen = () => {
    setOpenPhotoForm((prevState) => !prevState);
  };
  const handleVideoOpen = () => {
    setOpenVideoForm((prevState) => !prevState);
  };
  const handleMore = () => {
    setOpenMore((prevState) => !prevState);
  };
  const fileInputRef = useRef(null);
  const fileInputVideoRef = useRef(null);
  const fileInpuCovertRef = useRef(null);

  // eslint-disable-next-line no-unused-vars
  const timezones = moment.tz.names().map((tz) => {
    const offsetInMinutes = moment.tz(tz).utcOffset();
    const hours = Math.floor(Math.abs(offsetInMinutes) / 60)
      .toString()
      .padStart(2, "0"); // Ensures two digits
    const minutes = (Math.abs(offsetInMinutes) % 60)
      .toString()
      .padStart(2, "0"); // Ensures two digits
    const sign = offsetInMinutes >= 0 ? "+" : "-";
    const formattedOffset = `GMT ${sign}${hours}:${minutes}`;

    return {
      name: `${tz} (${formattedOffset})`,
      label: `${tz} (${formattedOffset})`,
    };
  });
  // eslint-disable-next-line no-unused-vars
  const formattedDate = selectedDate
    ? `${selectedDate.toLocaleDateString()} ${selectedDate.toLocaleTimeString()}`
    : null;
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleToggle = () => {
    setOpenToggle(true);
  };

  const handleToggleOne = () => {
    setScheduleToggle((prevState) => !prevState);
  };
  const handleDateChange = (date) => {
    setSelectedDate(date);
    // Do something with the selected date
  };
  const renderCustomHeader = ({ date, decreaseMonth, increaseMonth }) => (
    <div className="custom-datepicker-header">
      <button onClick={decreaseMonth}>{"<"}</button>
      <span>{date.toLocaleDateString()}</span>
      <button onClick={increaseMonth}>{">"}</button>
      <div style={{ display: "flex", gap: "20px", left: "10px" }}>
        <button onClick={() => setOpen(false)} style={{ fontSize: "20px" }}>
          Back
        </button>
      </div>
    </div>
  );
  const handlePrivacyChange = () => {
    setPrivacy((prevPrivacy) =>
      prevPrivacy === "ASSOCIATE" ? "PUBLIC" : "ASSOCIATE"
    );
  };
  const formatDate = (date) => {
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const day = String(date.getDate()).padStart(2, "0");
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  };
  const currentDate = new Date();
  const formattedDates = formatDate(currentDate);
  const handleSubmit = async () => {
    try {
      // Prepare media uploads
      const mediaUploads = [];

      // Handle image uploads if there are any
      if (images.length > 0) {
        const imageUploads = await Promise.all(
          images.map(async (file) => {
            const formData = new FormData();
            formData.append("image", file);

            // Upload image and get the URL
            const response = await axios.post(
              `${process.env.PUBLIC_URL}/xakal/files/multi-social`,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            );

            return response.data.urls.map((url) => ({
              type: file.type,
              url,
            }));
          })
        );

        mediaUploads.push(...imageUploads.flat()); // Flatten and add to mediaUploads
      }

      // Handle video uploads if there are any
      if (videos.length > 0) {
        const videoUploads = await Promise.all(
          videos.map(async (file) => {
            const formData = new FormData();
            formData.append("video", file);

            // Upload video and get the URL
            const response = await axios.post(
              `${process.env.PUBLIC_URL}/xakal/files/multi-social`,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            );

            return response.data.urls.map((url) => ({
              type: file.type,
              url,
            }));
          })
        );

        mediaUploads.push(...videoUploads.flat()); // Flatten and add to mediaUploads
      }

      const payload = {
        category: activeTab,
        text: inputValue,
        privacy: privacy,
        schedulePost: {
          schedulePost: scheduleToggle ? "Scheduled" : "Immediate",
          dateTime: scheduleToggle
            ? selectedDate.toISOString()
            : formattedDates,
          timezone: selectedTimezone,
        },
        media: mediaUploads, // Set media to the uploaded media array
        shortDesc: shortDesc,
        longDesc: longDesc,
      };

      const res = await axios.post(
        `${process.env.PUBLIC_URL}/xakal/create-social-post`,
        payload
      );

      // Reset form states
      setSelectedDate(new Date());
      setSelectedTimezone();
      setPrivacy("ASSOCIATE");
      setInputValue("");
      setImages([]); // Clear images after submission
      setVideos([]); // Clear videos after submission
      setOpenToggle(false);
      setScheduleToggle(false);
      setOpenSchedulePost(false);
      setOpenVideoForm(false);
      getAll();
      setOpenPhotoForm(false);
      console.log(res.data, "datasocial");
    } catch (error) {
      console.error("Error during submission:", error);
    }
  };

  const getMyInvitations = async () => {
    await axios
      .get(
        `${process.env.PUBLIC_URL}/xakal/get/myInvitations/${Common.loggedIn()}`
      )
      .then((res) => {
        console.log("my invitations", res?.data);
        const dt = res?.data?.filter((e) => e.connectionStatus === "ASSOCIATE");
        setInvitations(dt);
      });
  };
  useEffect(() => {
    getMyInvitations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    getAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (invitations.length > 0) {
      getAll();
    }
  }, [invitations]);

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);

    // Filter for valid image formats (png, jpg, gif, jpeg)
    const validImages = files.filter(
      (file) =>
        file.type === "image/png" ||
        file.type === "image/jpeg" ||
        file.type === "image/jpg" ||
        file.type === "image/gif"
    );

    // Check for invalid file formats
    const invalidFiles = files.filter(
      (file) =>
        file.type !== "image/png" &&
        file.type !== "image/jpeg" &&
        file.type !== "image/jpg" &&
        file.type !== "image/gif"
    );

    if (invalidFiles.length > 0) {
      toast.error("Only PNG, JPG, GIF, and JPEG formats are allowed.");
      return;
    }

    // Calculate the total size of selected images
    const totalSize = validImages.reduce((acc, file) => acc + file.size, 0);

    if (totalSize > 12 * 1024 * 1024) {
      // 12 MB
      toast.error(
        "Total image size exceeds 12 MB. Please select fewer images."
      );
      return;
    }

    setImages((prevImages) => [
      ...prevImages,
      ...validImages, // Store raw files for upload
    ]);
  };

  const handleVideoChange = (e) => {
    const files = Array.from(e.target.files);

    // Log the selected file types for debugging
    console.log("Selected files:", files);

    // Filter for valid video formats (mp4 and mp3)
    const validVideos = files.filter(
      (file) => file.type === "video/mp4" || file.type === "audio/mpeg"
    );

    // Check for invalid file formats
    const invalidFiles = files.filter(
      (file) => file.type !== "video/mp4" && file.type !== "audio/mpeg"
    );

    // If any invalid files are present, show an error
    if (invalidFiles.length > 0) {
      toast.error("Only MP4 and MP3 formats are allowed.");
      return;
    }

    // Calculate the total size of selected videos
    const totalSize = validVideos.reduce((acc, file) => acc + file.size, 0);

    // Check if the total size exceeds 200 MB
    if (totalSize > 200 * 1024 * 1024) {
      toast.error(
        "Total video size exceeds 200 MB. Please select fewer videos."
      );
      return;
    }

    // If everything is valid, update the state with the new videos
    setVideos((prevVideos) => [...prevVideos, ...validVideos]);
  };

  // In your render method:
  const handleDivCoverClick = () => {
    fileInpuCovertRef.current.click();
  };
  const handleDivClick = () => {
    fileInputRef.current.click();
  };
  const handleDivVideoClick = () => {
    fileInputVideoRef.current.click();
  };
  const removeImage = (index) => {
    setImages(images.filter((_, i) => i !== index));
  };
  const removeVideo = (index) => {
    setVideos(images.filter((_, i) => i !== index));
  };
  const getArticleTitleText = () => {
    return articleDescription.replace(/<[^>]+>/g, "").trim(); // Removes HTML tags and trims the text
  };
  const handleCoverChange = (e) => {
    const file = e.target.files[0]; // Get the first file (single image)

    // Check if a file is selected
    if (!file) {
      return;
    }

    // Check for valid image formats (png, jpg, gif, jpeg)
    const validImageTypes = [
      "image/png",
      "image/jpeg",
      "image/jpg",
      "image/gif",
    ];
    if (!validImageTypes.includes(file.type)) {
      toast.error("Only PNG, JPG, GIF, and JPEG formats are allowed.");
      return;
    }

    // Check if the image size exceeds the limit (12 MB)
    if (file.size > 12 * 1024 * 1024) {
      // 12 MB
      toast.error("Image size exceeds 12 MB. Please select a smaller image.");
      return;
    }

    // Update the state with the selected image
    setImagesCover(file); // Store a single image (not in an array)
  };
  const handleArticleSubmit = async () => {
    try {
      let imageUrl = null; // Variable to store the uploaded image URL

      // Handle image upload if there is a single cover image
      if (imagesCover) {
        const formData = new FormData();
        formData.append("image", imagesCover); // Append the single image file

        // Upload image and get the URL
        const response = await axios.post(
          `${process.env.PUBLIC_URL}/xakal/files/`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        imageUrl = response.data.url; // Get the image URL from the response
      }

      const mediaUploads = [];

      // Handle image uploads if there are any images
      if (images && images.length > 0) {
        // Check if images is not null and has content
        const imageUploads = await Promise.all(
          images.map(async (file) => {
            const formData = new FormData();
            formData.append("image", file);

            // Upload image and get the URL
            const response = await axios.post(
              `${process.env.PUBLIC_URL}/xakal/files/multi-social`,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            );

            return response.data.urls.map((url) => ({
              type: file.type,
              url,
            }));
          })
        );

        mediaUploads.push(...imageUploads.flat()); // Flatten and add to mediaUploads
      }

      // Construct the payload with the uploaded image URL
      const payload = {
        category: activeTab,
        text: inputValue,
        privacy: privacy,
        schedulePost: {
          schedulePost: scheduleToggle ? "Scheduled" : "Immediate",
          dateTime: scheduleToggle ? formattedDates : formattedDates,
          timezone: selectedTimezone,
        },
        media: mediaUploads, // If image exists, add to media
        shortDesc: shortDesc,
        longDesc: longDesc,
        coverPhoto: imageUrl, // Add the uploaded cover photo URL
        articleTitle: articleTitle,
        articleDescription: articleDescription,
      };

      // Make the request to create the social post
      const res = await axios.post(
        `${process.env.PUBLIC_URL}/xakal/create-social-post`,
        payload
      );

      // Reset form states after submission
      setSelectedDate(new Date());
      setSelectedTimezone();
      setPrivacy("ASSOCIATE");
      setInputValue("");
      setImages([]); // Clear the image after submission (set to empty array instead of null)
      setOpenToggle(false);
      setScheduleToggle(false);
      setOpenSchedulePost(false);
      setOpenVideoForm(false);
      getAll();
      setOpenPhotoForm(false);
      setArticleOpen(false);
      console.log(res.data, "datasocial");
    } catch (error) {
      console.error("Error during submission:", error);
    }
  };
  ///ebook form
  const [file, setFile] = useState([]);
  const [bookCover, setBookCover] = useState();
  const [bookPhoto, setBookPhoto] = useState([]);
  const [bookVideo, setBookVideo] = useState([]);
  const [bookTitle, setBookTitle] = useState("");

  const fileInputBookFileRef = useRef(null);
  const fileInputBookPhotoRef = useRef(null);
  const fileInputBookVideoRef = useRef(null);
  const fileInputBookCoverRef = useRef(null);
  const handleEbookImageChange = (e) => {
    const files = Array.from(e.target.files);

    // Filter for valid image formats (png, jpg, gif, jpeg)
    const validImages = files.filter(
      (file) =>
        file.type === "image/png" ||
        file.type === "image/jpeg" ||
        file.type === "image/jpg" ||
        file.type === "image/gif"
    );

    // Check for invalid file formats
    const invalidFiles = files.filter(
      (file) =>
        file.type !== "image/png" &&
        file.type !== "image/jpeg" &&
        file.type !== "image/jpg" &&
        file.type !== "image/gif"
    );

    if (invalidFiles.length > 0) {
      toast.error("Only PNG, JPG, GIF, and JPEG formats are allowed.");
      return;
    }

    // Calculate the total size of selected images
    const totalSize = validImages.reduce((acc, file) => acc + file.size, 0);

    if (totalSize > 12 * 1024 * 1024) {
      // 12 MB
      toast.error(
        "Total image size exceeds 12 MB. Please select fewer images."
      );
      return;
    }

    setBookPhoto((prevImages) => [
      ...prevImages,
      ...validImages, // Store raw files for upload
    ]);
  };

  const handleEbookVideoChange = (e) => {
    const files = Array.from(e.target.files);

    // Log the selected file types for debugging
    console.log("Selected files:", files);

    // Filter for valid video formats (mp4 and mp3)
    const validVideos = files.filter(
      (file) => file.type === "video/mp4" || file.type === "audio/mpeg"
    );

    // Check for invalid file formats
    const invalidFiles = files.filter(
      (file) => file.type !== "video/mp4" && file.type !== "audio/mpeg"
    );

    // If any invalid files are present, show an error
    if (invalidFiles.length > 0) {
      toast.error("Only MP4 and MP3 formats are allowed.");
      return;
    }

    // Calculate the total size of selected videos
    const totalSize = validVideos.reduce((acc, file) => acc + file.size, 0);

    // Check if the total size exceeds 200 MB
    if (totalSize > 200 * 1024 * 1024) {
      toast.error(
        "Total video size exceeds 200 MB. Please select fewer videos."
      );
      return;
    }

    // If everything is valid, update the state with the new videos
    setBookVideo((prevVideos) => [...prevVideos, ...validVideos]);
  };
  const handleEbookFileChange = (e) => {
    debugger;
    const files = Array.from(e.target.files);

    // Log the selected file types for debugging
    console.log("Selected files:", files);

    // Define allowed formats for different file types
    const allowedFormats = {
      document: ["application/pdf", "application/msword"],
    };

    // Filter for valid files
    const validFiles = files.filter((file) =>
      allowedFormats.document.includes(file.type)
    );

    // Check for invalid file formats
    const invalidFiles = files.filter(
      (file) => !allowedFormats.document.includes(file.type)
    );

    // If any invalid files are present, show an error
    if (invalidFiles.length > 0) {
      toast.error("Only MP4, MP3, PDF, and DOC formats are allowed.");
      return;
    }

    // Calculate the total size of selected videos and audio files
    const totalVideoAudioSize = validFiles
      .filter((file) => allowedFormats.document.includes(file.type))
      .reduce((acc, file) => acc + file.size, 0);

    // Check if the total size of videos and audios exceeds 12 MB
    if (totalVideoAudioSize > 12 * 1024 * 1024) {
      toast.error(
        "Total video/audio size exceeds 200 MB. Please select fewer files."
      );
      return;
    }

    // If everything is valid, update the state with the new files
    setFile((prevFiles) => [...prevFiles, ...validFiles]);
  };

  // In your render method:

  const handleDivBookFileClick = () => {
    fileInputBookFileRef.current.click();
  };
  const handleDivBookPhotoClick = () => {
    fileInputBookPhotoRef.current.click();
  };
  const handleDivBookVideoClick = () => {
    fileInputBookVideoRef.current.click();
  };
  const handleDivBookCoverClick = () => {
    fileInputBookCoverRef.current.click();
  };
  const removeBookImage = (index) => {
    setBookPhoto(bookPhoto.filter((_, i) => i !== index));
  };
  const removeBookVideo = (index) => {
    setBookVideo(bookVideo.filter((_, i) => i !== index));
  };
  const removeBookFile = (index) => {
    setFile(file.filter((_, i) => i !== index));
  };
  const handleBookCoverChange = (e) => {
    const file = e.target.files[0]; // Get the first file (single image)

    // Check if a file is selected
    if (!file) {
      return;
    }

    // Check for valid image formats (png, jpg, gif, jpeg)
    const validImageTypes = [
      "image/png",
      "image/jpeg",
      "image/jpg",
      "image/gif",
    ];
    if (!validImageTypes.includes(file.type)) {
      toast.error("Only PNG, JPG, GIF, and JPEG formats are allowed.");
      return;
    }

    // Check if the image size exceeds the limit (12 MB)
    if (file.size > 12 * 1024 * 1024) {
      // 12 MB
      toast.error("Image size exceeds 12 MB. Please select a smaller image.");
      return;
    }

    // Update the state with the selected image
    setBookCover(file); // Store a single image (not in an array)
  };
  const handleEbookSubmit = async () => {
    try {
      let imageUrl = null; // Variable to store the uploaded image URL

      // Handle image upload if there is a single cover image
      if (bookCover) {
        const formData = new FormData();
        formData.append("image", bookCover); // Append the single image file

        // Upload image and get the URL
        const response = await axios.post(
          `${process.env.PUBLIC_URL}/xakal/files/`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        imageUrl = response.data.url; // Get the image URL from the response
      }

      const bookVideoUpload = [];

      // Handle image uploads if there are any images
      if (bookVideo && bookVideo.length > 0) {
        // Check if images is not null and has content
        const imageUploads = await Promise.all(
          bookVideo.map(async (file) => {
            const formData = new FormData();
            formData.append("image", file);

            // Upload image and get the URL
            const response = await axios.post(
              `${process.env.PUBLIC_URL}/xakal/files/multi-social`,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            );

            return response.data.urls.map((url) => ({
              type: file.type,
              url,
            }));
          })
        );

        bookVideoUpload.push(...imageUploads.flat()); // Flatten and add to mediaUploads
      }
      const bookImageUpload = [];

      // Handle image uploads if there are any images
      if (bookPhoto && bookPhoto.length > 0) {
        // Check if images is not null and has content
        const imageUploads = await Promise.all(
          bookPhoto.map(async (file) => {
            const formData = new FormData();
            formData.append("image", file);

            // Upload image and get the URL
            const response = await axios.post(
              `${process.env.PUBLIC_URL}/xakal/files/multi-social`,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            );

            return response.data.urls.map((url) => ({
              type: file.type,
              url,
            }));
          })
        );

        bookImageUpload.push(...imageUploads.flat()); // Flatten and add to mediaUploads
      }
      const bookFileUpload = [];

      // Handle image uploads if there are any images
      if (file && file.length > 0) {
        // Check if images is not null and has content
        const imageUploads = await Promise.all(
          file.map(async (file) => {
            const formData = new FormData();
            formData.append("image", file);

            // Upload image and get the URL
            const response = await axios.post(
              `${process.env.PUBLIC_URL}/xakal/files/multi-social`,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            );

            return response.data.urls.map((url) => ({
              type: file.type,
              url,
            }));
          })
        );

        bookFileUpload.push(...imageUploads.flat()); // Flatten and add to mediaUploads
      }
      // Construct the payload with the uploaded image URL
      const payload = {
        category: activeTab,
        bookTitle: bookTitle,
        privacy: privacy,
        schedulePost: {
          schedulePost: scheduleToggle ? "Scheduled" : "Immediate",
          dateTime: scheduleToggle
            ? selectedDate.toISOString()
            : formattedDates,
          timezone: selectedTimezone,
        },

        shortDesc: shortDesc,
        longDesc: longDesc,
        coverPhoto: imageUrl, // Add the uploaded cover photo URL

        videoBookMedia: bookVideoUpload,
        imageBookMedia: bookImageUpload,
        fileBookMedia: bookFileUpload,
      };

      // Make the request to create the social post
      const res = await axios.post(
        `${process.env.PUBLIC_URL}/xakal/create-social-post`,
        payload
      );

      // Reset form states after submission
      setSelectedDate(new Date());
      setSelectedTimezone();
      setPrivacy("ASSOCIATE");
      setInputValue("");
      setImages([]); // Clear the image after submission (set to empty array instead of null)
      setOpenToggle(false);
      setScheduleToggle(false);
      setOpenSchedulePost(false);
      setOpenVideoForm(false);
      getAll();
      setOpenPhotoForm(false);
      setEbookOpen(false);
      console.log(res.data, "datasocial");
    } catch (error) {
      console.error("Error during submission:", error);
    }
  };
  //end of ebook

  //pollstart
  const [pollOpen, setPollOpen] = useState(false);
  const [pollTitle, setPollTitle] = useState("");
  const [pollCaption, setPollCaption] = useState("");
  const [pollOptions, setPollOptions] = useState([
    { optionText: "", votes: 0 },
  ]);
  const handlePoleSubmit = async () => {
    try {
      const payload = {
        category: activeTab,
        pollTitle: pollTitle,
        privacy: privacy,
        schedulePost: {
          schedulePost: scheduleToggle ? "Scheduled" : "Immediate",
          dateTime: scheduleToggle
            ? selectedDate.toISOString()
            : formattedDates,
          timezone: selectedTimezone,
        },

        pollCaptions: pollCaption,
        optionsPoll: pollOptions,
      };

      // Make the request to create the social post
      const res = await axios.post(
        `${process.env.PUBLIC_URL}/xakal/create-social-post`,
        payload
      );
      setPollTitle("");
      setPollCaption("");
      setPollOptions([]);
      setPollOpen(false);
      setSelectedDate(new Date());
      setSelectedTimezone();
      setPrivacy("ASSOCIATE");
      setInputValue("");
      setImages([]); // Clear the image after submission (set to empty array instead of null)
      setOpenToggle(false);
      setScheduleToggle(false);
      setOpenSchedulePost(false);
      setOpenVideoForm(false);
      getAll();
      setOpenPhotoForm(false);
    } catch (error) {
      console.error("Error during submission:", error);
    }
  };
  ///podcast flow
  const [podcastOpen, setPodcastOpen] = useState(false);
  const [podcastTitle, setPodcastTitle] = useState("");
  const [eventFormat, setEventFormat] = useState("");
  const [imagesVideo, setImageVideo] = useState(null);
  const [imagesAudio, setImageAudio] = useState(null);
  const [userArray, setUserArray] = useState([]);
  const [imageCoverPodcast, setImagesCoverPodcast] = useState(null);
  const [userSelect, setUserSelect] = useState([]);
  const optionFormat = [
    { label: "Audio", value: "Audio" },
    { label: "Video", value: "Video" },
  ];
  const handlePodcastTitle = (e) => {
    setPodcastTitle(e.target.value);
  };
  const handleFormatChange = (e) => {
    setEventFormat(e);
  };
  const fileInputPodcastRef = useRef();

  const handleDivPodcastClick = () => {
    fileInputPodcastRef.current.click();
  };
  const fileInputPodcasImagetRef = useRef();
  const fileInputPodcastAudioRef = useRef();

  const handleDivPodcastImageClick = () => {
    fileInputPodcasImagetRef.current.click();
  };
  const handleDivPodcastAudioClick = () => {
    fileInputPodcastAudioRef.current.click();
  };
  const handleVideoPodcast = (e) => {
    const file = e.target.files[0]; // Get the first file (single video/audio file)

    // Check if a file is selected
    if (!file) {
      return;
    }

    // Check for valid video/audio formats (mp4, mp3)
    const validMediaTypes = ["video/mp4", "audio/mp3"];
    if (!validMediaTypes.includes(file.type)) {
      toast.error("Only MP4 and MP3 formats are allowed.");
      return;
    }

    // Check if the file size exceeds the limit (12 MB)
    if (file.size > 12 * 1024 * 1024) {
      toast.error("File size exceeds 12 MB. Please select a smaller file.");
      return;
    }

    // Update the state with the selected media file
    setImageVideo(file); // Store a single media file
  };
  const userIdSelect = userSelect.map((user) => ({
    name: user.name,
    userId: user.userId,
  }));
  const handleCoverPodcastChange = (e) => {
    const file = e.target.files[0]; // Get the first file (single image)

    // Check if a file is selected
    if (!file) {
      return;
    }

    // Check for valid image formats (png, jpg, gif, jpeg)
    const validImageTypes = [
      "image/png",
      "image/jpeg",
      "image/jpg",
      "image/gif",
    ];
    if (!validImageTypes.includes(file.type)) {
      toast.error("Only PNG, JPG, GIF, and JPEG formats are allowed.");
      return;
    }

    // Check if the image size exceeds the limit (12 MB)
    if (file.size > 12 * 1024 * 1024) {
      // 12 MB
      toast.error("Image size exceeds 12 MB. Please select a smaller image.");
      return;
    }

    // Update the state with the selected image
    setImagesCoverPodcast(file); // Store a single image (not in an array)
  };
  const handleAudioPodcast = (e) => {
    const file = e.target.files[0]; // Get the first file (single image)

    // Check if a file is selected
    if (!file) {
      return;
    }

    // Check for valid image formats (png, jpg, gif, jpeg)
    const validImageTypes = [
      "image/png",
      "image/jpeg",
      "image/jpg",
      "image/gif",
    ];
    if (!validImageTypes.includes(file.type)) {
      toast.error("Only PNG, JPG, GIF, and JPEG formats are allowed.");
      return;
    }

    // Check if the image size exceeds the limit (12 MB)
    if (file.size > 12 * 1024 * 1024) {
      // 12 MB
      toast.error("Image size exceeds 12 MB. Please select a smaller image.");
      return;
    }

    // Update the state with the selected image
    setImageAudio(file); // Store a single image (not in an array)
  };
  const handlePodCastSubmit = async () => {
    try {
      let imagePodcast = null; // Store the uploaded cover image URL
      let podcastMedia = null; // Store the media URL (video or audio)

      // Handle cover image upload if there's a single cover image
      if (imageCoverPodcast) {
        const formData = new FormData();
        formData.append("image", imageCoverPodcast); // Append the cover image file

        // Upload cover image and get the URL
        const response = await axios.post(
          `${process.env.PUBLIC_URL}/xakal/files/`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        imagePodcast = response.data.url; // Store the cover image URL
      }

      // Handle media upload based on whether it’s an audio or video file
      if (imagesVideo || imagesAudio) {
        const formData = new FormData();
        formData.append("image", imagesVideo || imagesAudio); // Append the media file

        // Upload media file and get the URL
        const response = await axios.post(
          `${process.env.PUBLIC_URL}/xakal/files/`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        podcastMedia = response.data.url; // Store the media URL
      }

      // Prepare the payload based on the type of media
      const payload = {
        category: activeTab,
        podcastTitle: podcastTitle,
        privacy: privacy,
        schedulePost: {
          schedulePost: scheduleToggle ? "Scheduled" : "Immediate",
          dateTime: scheduleToggle
            ? selectedDate.toISOString()
            : formattedDates,
          timezone: selectedTimezone,
        },
        coverPhoto: imagePodcast,
        podcastType: eventFormat.value,
        podcastMedia: podcastMedia, // Use the media URL for video or audio
        podcastUserArray:userIdSelect,
      };

      // Make the request to create the social post
      const res = await axios.post(
        `${process.env.PUBLIC_URL}/xakal/create-social-post`,
        payload
      );

      // Reset state after submission
      setPollTitle("");
      setPollCaption("");
      setPollOptions([]);
      setPollOpen(false);
      setSelectedDate(new Date());
      setSelectedTimezone();
      setPrivacy("ASSOCIATE");
      setInputValue("");
      setImages([]); // Clear images
      setOpenToggle(false);
      setScheduleToggle(false);
      setOpenSchedulePost(false);
      setImagesCoverPodcast("");
      setImageAudio("");
      setImageVideo("");
      getAll();
      setPodcastOpen(false);
    } catch (error) {
      console.error("Error during submission:", error);
    }
  };
  useEffect(() => {
    const fetchData = async() => {
      try{
        const res = await axios
      .get(`${process.env.PUBLIC_URL}/xakal/get-all-user-list`)
      
        const userData = res.data.user.map((user) => ({
          name: `${user.firstName} ${user.lastName}`,
          userId: user.userId,
        }));
        setUserArray(userData);
      }
      catch(err)  {console.log("Error fetching details:",err)};
    }
    
    fetchData();
  }, []);
  return (
    <>
      <ToastContainer />
      <div className="cardContainer">
        <div className="contentDiv">
          <img
            src={input?.avatar ? input?.avatar : companyLogo}
            alt="Profile"
            className="profileImage"
          />
          <div className="textContent">
            {inputValue?.length > 0 ? (
              <textarea
                value={inputValue}
                placeholder="What's on your mind?"
                className="inputField"
                onChange={handleInputChange}
                rows={3}
              />
            ) : (
              <input
                type="text"
                placeholder="What's on your mind?"
                className="inputField"
                value={inputValue}
                onChange={handleInputChange}
              />
            )}
          </div>
        </div>
        <div className="character">{inputValue?.length}/3000 Characters</div>
        <div className="optionsContainer">
          {activeTab === "Photo" || activeTab === "Video" ? (
            <>
              {" "}
              <div
                className="option"
                onClick={handlePhotoOpen}
                style={{
                  cursor: "pointer",
                  padding: "10px",
                  background: "#f0f0f0",
                  border: "1px solid #ccc",
                  display: "flex",
                  gap: "10px",
                }}
              >
                <FaPhotoVideo size={20} />
                <div>Photo</div>
              </div>
            </>
          ) : (
            <>
              <div className="option" onClick={handleDivClick}>
                <FaPhotoVideo size={20} />
                Photo
              </div>
              <input
                type="file"
                accept="image/*"
                multiple
                ref={fileInputRef}
                style={{ display: "none" }} // Hide the input
                onChange={handleImageChange}
              />
            </>
          )}
          {activeTab === "Video" || activeTab === "Photo" ? (
            <>
              <div
                className="option"
                onClick={handleVideoOpen}
                style={{
                  cursor: "pointer",
                  padding: "10px",
                  background: "#f0f0f0",
                  border: "1px solid #ccc",
                }}
              >
                <FaVideo size={20} />
                Video
              </div>
            </>
          ) : (
            <>
              <div
                className="option"
                onClick={handleDivVideoClick}
                style={{
                  cursor: "pointer",
                  padding: "10px",
                  background: "#f0f0f0",
                  border: "1px solid #ccc",
                }}
              >
                <FaVideo size={20} />
                Video
              </div>
              <input
                type="file"
                accept="video/*"
                multiple
                ref={fileInputVideoRef}
                style={{ display: "none" }} // Hide the input
                onChange={handleVideoChange}
              />
            </>
          )}

          <div
            className="option"
            style={{
              cursor: "pointer",
              padding: "10px",
              background: "#f0f0f0",
              border: "1px solid #ccc",
            }}
          >
            <MdWork size={20} />
            Job
          </div>
          <div
            className="option"
            onClick={handleMore}
            style={{
              cursor: "pointer",
              padding: "10px",
              background: "#f0f0f0",
              border: "1px solid #ccc",
            }}
          >
            {openMore ? (
              <>
                <IoMdArrowDropdown size={20} />
                <span style={{ marginLeft: "5px" }}>Less</span>
              </>
            ) : (
              <>
                <RxHamburgerMenu size={20} />
                <span style={{ marginLeft: "5px" }}>More</span>
              </>
            )}
          </div>
        </div>
        {openMore && (
          <>
            <div className="optionsContainer">
              <div
                className="option"
                onClick={handleArticleForm}
                style={{
                  cursor: "pointer",
                  padding: "10px",
                  background: "#f0f0f0",
                  border: "1px solid #ccc",
                }}
              >
                <RiArticleLine size={20} />
                Article
              </div>

              <div
                className="option"
                style={{
                  cursor: "pointer",
                  padding: "10px",
                  background: "#f0f0f0",
                  border: "1px solid #ccc",
                }}
              >
                <FaBookOpen size={20} />
                Course
              </div>

              <div
                className="option"
                onClick={handleEbookOpen}
                style={{
                  cursor: "pointer",
                  padding: "10px",
                  background: "#f0f0f0",
                  border: "1px solid #ccc",
                }}
              >
                <TbBookFilled size={20} />E Book
              </div>
              <div
                className="option"
                onClick={handleDivVideoClick}
                style={{
                  cursor: "pointer",
                  padding: "10px",
                  background: "#f0f0f0",
                  border: "1px solid #ccc",
                }}
              >
                <AiOutlineSnippets size={20} />
                Snippet
              </div>
            </div>
            <div className="optionsContainer">
              <div
                className="option"
                onClick={handleDivClick}
                style={{
                  cursor: "pointer",
                  padding: "10px",
                  background: "#f0f0f0",
                  border: "1px solid #ccc",
                }}
              >
                <MdEvent size={20} />
                Event
              </div>

              <div
                className="option"
                onClick={() => setPollOpen(true)}
                style={{
                  cursor: "pointer",
                  padding: "10px",
                  background: "#f0f0f0",
                  border: "1px solid #ccc",
                }}
              >
                <FaPoll size={20} />
                Polls
              </div>

              <div
                className="option"
                onClick={() => setPodcastOpen((prevstate) => !prevstate)}
                style={{
                  cursor: "pointer",
                  padding: "10px",
                  background: "#f0f0f0",
                  border: "1px solid #ccc",
                }}
              >
                <FaPodcast size={20} />
                Podcast
              </div>
              <div
                className="option"
                style={{
                  cursor: "pointer",
                  padding: "10px",
                  background: "#f0f0f0",
                  border: "1px solid #ccc",
                }}
              >
                <CiStreamOn size={20} />
                Live
              </div>
            </div>
          </>
        )}
        {activeTab === "Photo" || activeTab === "Article" ? (
          ""
        ) : (
          <>
            {" "}
            {images.length > 0 && (
              <div>
                <h2>Preview:</h2>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  {images.map((image, index) => (
                    <div
                      key={index}
                      style={{ position: "relative", margin: "5px" }}
                    >
                      <img
                        src={URL.createObjectURL(image)}
                        alt={`Preview ${index}`}
                        style={{
                          maxWidth: "150px",
                          maxHeight: "150px",
                          borderRadius: "4px",
                        }}
                      />
                      <button
                        onClick={() => removeImage(index)}
                        style={{
                          position: "absolute",
                          top: "5px",
                          right: "5px",
                          background: "rgba(255, 255, 255, 0.7)",
                          border: "none",
                          borderRadius: "50%",
                          cursor: "pointer",
                          padding: "2px",
                        }}
                      >
                        ✖️
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </>
        )}
        {activeTab === "Video" ? (
          ""
        ) : (
          <>
            {videos.length > 0 && (
              <div>
                <h2>Preview:</h2>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  {videos.map((image, index) => (
                    <div
                      key={index}
                      style={{ position: "relative", margin: "5px" }}
                    >
                      <img
                        src={URL.createObjectURL(image)}
                        alt={`Preview ${index}`}
                        style={{
                          maxWidth: "150px",
                          maxHeight: "150px",
                          borderRadius: "4px",
                        }}
                      />
                      <button
                        onClick={() => removeVideo(index)}
                        style={{
                          position: "absolute",
                          top: "5px",
                          right: "5px",
                          background: "rgba(255, 255, 255, 0.7)",
                          border: "none",
                          borderRadius: "50%",
                          cursor: "pointer",
                          padding: "2px",
                        }}
                      >
                        ✖️
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </>
        )}

        <div className="buttonDiv">
          <button className="button2" onClick={handleToggle}>
            Next
          </button>
        </div>
      </div>
      <Dialog
        open={openToggle}
        PaperProps={{ className: "dialogPaper" }}
        fullWidth
        maxWidth="sm"
      >
        <DialogContent>
          <div className="dialogContent">
            <button className="button2" onClick={() => setOpenToggle(false)}>
              Back
            </button>
          </div>
          <div className="toggleContainer">
            <span> Private</span>
            <Toggle
              onChange={handlePrivacyChange}
              checked={privacy === "PUBLIC"}
            />
            <span> Public</span>
          </div>
          <div className="dialogButtonContainer">
            <button
              className="button2"
              onClick={() => setOpenSchedulePost(true)}
            >
              Next
            </button>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog
        open={openSchedulePost}
        PaperProps={{ className: "dialogPapers" }}
        fullWidth
        maxWidth="sm"
      >
        <DialogContent>
          <div className="dialogContent">
            <button
              className="button2"
              onClick={() => setOpenSchedulePost(false)}
            >
              Back
            </button>
          </div>
          <h4>Schedule Post</h4>
          <div className="toggleContainer">
            <div>
              <span>Post Immediately</span>
              <Toggle onChange={handleToggleOne} checked={scheduleToggle} />
              <span>Schedule Post</span>
            </div>
          </div>
          {scheduleToggle && (
            <div>
              <div className="scheduleOptions">
                <div className="scheduleTime">
                  {showDatePicker && (
                    <DatePicker
                      selected={selectedDate}
                      onChange={handleDateChange}
                      showTimeSelect
                      timeFormat="HH:mm aa"
                      timeIntervals={15}
                      dateFormat="MMMM d, yyyy h:mm aa"
                      open={showDatePicker}
                      onCalendarOpen={() => setShowDatePicker(true)}
                      onCalendarClose={() => setShowDatePicker(false)}
                      renderCustomHeader={renderCustomHeader}
                    />
                  )}

                  <label>Date and Time</label>
                  <div
                    className={
                      showDatePicker ? "schedule-foot" : "schedule-footer"
                    }
                  >
                    <button
                      className="button5"
                      style={{ width: "160px" }}
                      onClick={() => setShowDatePicker(true)}
                    >
                      <img src={Calender} alt="img" style={{ width: "30px" }} />
                      Schedule
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="dialogButtonContainer">
            {activeTab === "Article" ? (
              <button className="button2" onClick={handleArticleSubmit}>
                Post
              </button>
            ) : activeTab === "Ebook" ? (
              <button className="button" onClick={handleEbookSubmit}>
                Post
              </button>
            ) : activeTab === "Polls" ? (
              <button className="button1" onClick={handlePoleSubmit}>
                Post
              </button>
            ) : activeTab === "Podcast" ? (
              <button className="button2" onClick={handlePodCastSubmit}>
                Post
              </button>
            ) : (
              <button className="button2" onClick={handleSubmit}>
                Post
              </button>
            )}
          </div>
        </DialogContent>
      </Dialog>
      <Dialog
        open={openPhotoForm}
        PaperProps={{ className: "dialogPaper" }}
        fullWidth
        maxWidth="sm"
      >
        <div className="mheader modalHeader">
          <div className="mTitle conver-name"></div>

          <img
            src={CloseIcon}
            onClick={() => setOpenPhotoForm(false)}
            alt="ij"
          />
        </div>
        <DialogContent style={{ margin: "15px", overflowY: "hidden" }}>
          <div className="optionsContainer">
            <div
              className="option"
              onClick={handleDivClick}
              style={{
                cursor: "pointer",
                padding: "10px",
                background: "#f0f0f0",
                border: "1px solid #ccc",
              }}
            >
              <FaPhotoVideo size={20} />
              Photo +
            </div>
            <input
              type="file"
              accept="image/*"
              multiple
              ref={fileInputRef}
              style={{ display: "none" }} // Hide the input
              onChange={handleImageChange}
            />
          </div>
          {images.length > 0 && (
            <div>
              <h2>Preview:</h2>
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                {images.map((image, index) => (
                  <div
                    key={index}
                    style={{ position: "relative", margin: "5px" }}
                  >
                    <img
                      src={URL.createObjectURL(image)}
                      alt={`Preview ${index}`}
                      style={{
                        maxWidth: "150px",
                        maxHeight: "150px",
                        borderRadius: "4px",
                      }}
                    />
                    <button
                      onClick={() => removeImage(index)}
                      style={{
                        position: "absolute",
                        top: "5px",
                        right: "5px",
                        background: "rgba(255, 255, 255, 0.7)",
                        border: "none",
                        borderRadius: "50%",
                        cursor: "pointer",
                        padding: "2px",
                      }}
                    >
                      ✖️
                    </button>
                  </div>
                ))}
              </div>
            </div>
          )}
          <div className="textContent">
            Short Description
            <textarea
              value={shortDesc}
              placeholder="What's on your mind?"
              className="inputField"
              onChange={(e) => setShortDesc(e.target.value)}
              rows={3}
            />
            <div className="character">{shortDesc?.length}/300 Characters</div>
          </div>
          <div className="textContent">
            Long Description
            <textarea
              value={longDesc}
              placeholder="What's on your mind?"
              className="inputField"
              onChange={(e) => setLongDesc(e.target.value)}
              rows={3}
            />
            <div className="character">{longDesc?.length}/3000 Characters</div>
          </div>
          <div className="buttonDiv">
            <button className="button2" onClick={handleToggle}>
              Next
            </button>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog
        open={openVideoForm}
        PaperProps={{ className: "dialogPaper" }}
        fullWidth
        maxWidth="sm"
      >
        <div className="mheader modalHeader">
          <div className="mTitle conver-name"></div>

          <img
            src={CloseIcon}
            onClick={() => setOpenVideoForm(false)}
            alt="ij"
          />
        </div>
        <DialogContent style={{ margin: "15px", overflowY: "hidden" }}>
          <div className="optionsContainer">
            <div
              className="option"
              onClick={handleDivClick}
              style={{
                cursor: "pointer",
                padding: "10px",
                background: "#f0f0f0",
                border: "1px solid #ccc",
              }}
            >
              <FaPhotoVideo size={20} />
              Video +
            </div>
            <input
              type="file"
              accept="image/*"
              multiple
              ref={fileInputRef}
              style={{ display: "none" }} // Hide the input
              onChange={handleImageChange}
            />
          </div>
          {videos.length > 0 && (
            <div>
              <h2>Preview:</h2>
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                {videos.map((image, index) => (
                  <div
                    key={index}
                    style={{ position: "relative", margin: "5px" }}
                  >
                    <img
                      src={URL.createObjectURL(image)}
                      alt={`Preview ${index}`}
                      style={{
                        maxWidth: "150px",
                        maxHeight: "150px",
                        borderRadius: "4px",
                      }}
                    />
                    <button
                      onClick={() => removeVideo(index)}
                      style={{
                        position: "absolute",
                        top: "5px",
                        right: "5px",
                        background: "rgba(255, 255, 255, 0.7)",
                        border: "none",
                        borderRadius: "50%",
                        cursor: "pointer",
                        padding: "2px",
                      }}
                    >
                      ✖️
                    </button>
                  </div>
                ))}
              </div>
            </div>
          )}
          <div className="textContent">
            Short Description
            <textarea
              value={shortDesc}
              placeholder="What's on your mind?"
              className="inputField"
              onChange={(e) => setShortDesc(e.target.value)}
              rows={3}
            />
            <div className="character">{shortDesc?.length}/300 Characters</div>
          </div>
          <div className="textContent">
            Long Description
            <textarea
              value={longDesc}
              placeholder="What's on your mind?"
              className="inputField"
              onChange={(e) => setLongDesc(e.target.value)}
              rows={3}
            />
            <div className="character">{longDesc?.length}/3000 Characters</div>
          </div>
          <div className="buttonDiv">
            <button className="button2" onClick={handleToggle}>
              Next
            </button>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog
        open={articleOpen}
        PaperProps={{ className: "dialogPapers" }}
        fullWidth
        maxWidth="sm"
      >
        <div className="mheader modalHeader">
          <div className="mTitle conver-name"></div>

          <img src={CloseIcon} onClick={() => setArticleOpen(false)} alt="ij" />
        </div>
        <DialogContent style={{ margin: "15px" }}>
          <div className="textContent">
            <input
              type="text"
              placeholder="What's on your mind?"
              className="inputField"
              value={articleTitle}
              onChange={(e) => setArticleTitle(e.target.value)}
            />
          </div>
          <div className="character">{articleTitle?.length}/100 Characters</div>
          <ReactQuill
            value={articleDescription}
            onChange={setArticleDescription}
            placeholder="What's on your mind?"
            theme="snow"
            modules={{
              toolbar: [
                [{ header: "1" }, { header: "2" }, { font: [] }],
                [{ list: "ordered" }, { list: "bullet" }],
                ["bold", "italic", "underline"],
                ["link"],
                ["blockquote", "code-block"],
                [{ align: [] }],
                ["image"],
                ["clean"],
              ],
            }}
          />
          <div className="character">
            {getArticleTitleText().length}/110000 Characters
          </div>

          <div className="optionsContainer">
            <div
              className="option"
              onClick={handleDivClick}
              style={{
                cursor: "pointer",
                padding: "10px",
                background: "#f0f0f0",
                border: "1px solid #ccc",
              }}
            >
              <FaPhotoVideo size={20} />
              Photo +
            </div>
            <input
              type="file"
              accept="image/*"
              multiple
              ref={fileInputRef}
              style={{ display: "none" }} // Hide the input
              onChange={handleImageChange}
            />
          </div>
          {images.length > 0 && (
            <div>
              <h2>Preview:</h2>
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                {images.map((image, index) => (
                  <div
                    key={index}
                    style={{ position: "relative", margin: "5px" }}
                  >
                    <img
                      src={URL.createObjectURL(image)}
                      alt={`Preview ${index}`}
                      style={{
                        maxWidth: "150px",
                        maxHeight: "150px",
                        borderRadius: "4px",
                      }}
                    />
                    <button
                      onClick={() => removeImage(index)}
                      style={{
                        position: "absolute",
                        top: "5px",
                        right: "5px",
                        background: "rgba(255, 255, 255, 0.7)",
                        border: "none",
                        borderRadius: "50%",
                        cursor: "pointer",
                        padding: "2px",
                      }}
                    >
                      ✖️
                    </button>
                  </div>
                ))}
              </div>
            </div>
          )}
          <div className="optionsContainer">
            <div
              className="option"
              onClick={handleDivCoverClick}
              style={{
                cursor: "pointer",
                padding: "10px",
                background: "#f0f0f0",
                border: "1px solid #ccc",
              }}
            >
              <FaPhotoVideo size={20} />
              Upload
            </div>
            <input
              type="file"
              accept="image/*"
              multiple
              ref={fileInpuCovertRef}
              style={{ display: "none" }} // Hide the input
              onChange={handleCoverChange}
            />
          </div>
          {imagesCover && (
            <img
              src={URL.createObjectURL(imagesCover)} // Create URL for the selected image
              alt="Selected Image"
              style={{
                maxWidth: "150px",
                maxHeight: "150px",
                borderRadius: "4px",
              }}
            />
          )}
          <div className="textContent">
            Short Description
            <textarea
              value={shortDesc}
              placeholder="What's on your mind?"
              className="inputField"
              onChange={(e) => setShortDesc(e.target.value)}
              rows={3}
            />
            <div className="character">{shortDesc?.length}/300 Characters</div>
          </div>
          <div className="textContent">
            Long Description
            <textarea
              value={longDesc}
              placeholder="What's on your mind?"
              className="inputField"
              onChange={(e) => setLongDesc(e.target.value)}
              rows={3}
            />
            <div className="character">{longDesc?.length}/3000 Characters</div>
          </div>
          <div className="buttonDiv">
            <button className="button2" onClick={handleToggle}>
              Next
            </button>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog
        open={ebookOpen}
        PaperProps={{ className: "dialogPapers" }}
        fullWidth
        maxWidth="sm"
      >
        <div className="mheader modalHeader">
          <div className="mTitle conver-name"></div>

          <img src={CloseIcon} onClick={() => setEbookOpen(false)} alt="ij" />
        </div>
        <DialogContent style={{ margin: "15px" }}>
          <EbookMediaForm
            handleToggle={handleToggle}
            shortDesc={shortDesc}
            setShortDesc={setShortDesc}
            longDesc={longDesc}
            setLongDesc={setLongDesc}
            bookTitle={bookTitle}
            setBookTitle={setBookTitle}
            handleDivBookFileClick={handleDivBookFileClick}
            fileInputBookFileRef={fileInputBookFileRef}
            handleEbookFileChange={handleEbookFileChange}
            fileInputBookVideoRef={fileInputBookVideoRef}
            handleEbookVideoChange={handleEbookVideoChange}
            handleCoverChange={handleBookCoverChange}
            bookVideo={bookVideo}
            handleDivBookCoverClick={handleDivBookCoverClick}
            fileInputBookCoverRef={fileInputBookCoverRef}
            file={file}
            setFile={setFile}
            bookCover={bookCover}
            setBookCover={setBookCover}
            removeBookFile={removeBookFile}
            handleDivBookPhotoClick={handleDivBookPhotoClick}
            fileInputBookPhotoRef={fileInputBookPhotoRef}
            handleEbookImageChange={handleEbookImageChange}
            bookPhoto={bookPhoto}
            removeBookVideo={removeBookVideo}
            removeBookImage={removeBookImage}
            handleDivBookVideoClick={handleDivBookVideoClick}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={pollOpen}
        PaperProps={{ className: "dialogPapers" }}
        fullWidth
        maxWidth="sm"
      >
        <div className="mheader modalHeader">
          <div className="mTitle conver-name"></div>

          <img src={CloseIcon} onClick={() => setPollOpen(false)} alt="ij" />
        </div>
        <DialogContent style={{ margin: "15px" }}>
          <PollMediaForm
            handleToggle={handleToggle}
            pollTitle={pollTitle}
            setPollTitle={setPollTitle}
            pollOption={pollOptions}
            setPollOption={setPollOptions}
            pollCaption={pollCaption}
            setPollCaption={setPollCaption}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={podcastOpen}
        PaperProps={{ className: "dialogPapers" }}
        fullWidth
        maxWidth="sm"
      >
        <div className="mheader modalHeader">
          <div className="mTitle conver-name"></div>

          <img src={CloseIcon} onClick={() => setPodcastOpen(false)} alt="ij" />
        </div>
        <DialogContent style={{ margin: "15px" }}>
          <PodCastForm
            handleToggle={handleToggle}
            podcastTitle={podcastTitle}
            setPodcastTitle={setPodcastTitle}
            handlePodcastTitle={handlePodcastTitle}
            optionFormat={optionFormat}
            handleFormatChange={handleFormatChange}
            eventFormat={eventFormat}
            shortDesc={shortDesc}
            setShortDesc={setShortDesc}
            longDesc={longDesc}
            setLongDesc={setLongDesc}
            handleDivPodcastClick={handleDivPodcastClick}
            handleVideoPodcast={handleVideoPodcast}
            fileInputPodcastRef={fileInputPodcastRef}
            imagesVideo={imagesVideo}
            handleCoverPodcastChange={handleCoverPodcastChange}
            handleDivPodcastImageClick={handleDivPodcastImageClick}
            imageCoverPodcast={imageCoverPodcast}
            fileInputPodcasImagetRef={fileInputPodcasImagetRef}
            handleDivPodcastAudioClick={handleDivPodcastAudioClick}
            handleAudioPodcast={handleAudioPodcast}
            fileInputPodcastAudioRef={fileInputPodcastAudioRef}
            imagesAudio={imagesAudio}
            userArray={userArray}
            setUserSelect={setUserSelect}

          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default TextCard;
